body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  /* Prevents horizontal scroll */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  /* Using the 600 weight */
}

p,
a,
li,
td {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

p {
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
}


.navbar {
  background-color: transparent;
  border-bottom: 1px solid rgb(196, 196, 196);
}


.bg-theme {
  background-color: #27333D;
}

.text-theme {
  color: #495C6A
}

.text-golden {
  color: #D1B67F
}

.btn-theme {
  background-color: #27333D;
  color: white;
  width: 250px;
  height: 50px;
  border-top-left-radius: 0px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}

.btn-theme:hover {
  background-color: #27333D;
  color: white;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 260px;
  height: 60px;
}

.btn-golden {
  background-color: #D1B67F;
  width: 250px;
  height: 50px;
  border-top-left-radius: 0px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  transition: all 0.5s ease;
}

.btn-golden:hover {
  background-color: #D1B67F;
  color: black;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 260px;
  height: 60px;
}

.shadow--golden-1 {
  box-shadow: -25px -12px 0px rgba(209, 182, 127, 1);
}

.shadow-golden-2 {
  box-shadow: 25px -12px 0px rgba(209, 182, 127, 1);
}

.shadow-golden-3 {
  box-shadow: -10px 0px 0px rgba(209, 182, 127, 1);
}

.payment-card {
  border-radius: 32px 50px 0px 0px;
}



.card {
  border-radius: 0px;
}

.hero-1 {
  min-height: 84vh;
  width: 100%;
}

.hero-1-section-2-img {
  max-width: 90vh;
  height: auto;
  /* margin-right: -100px; */
  border-top-left-radius: 150px;
  border-bottom-right-radius: 150px;
}

.hero-3 {
  border: 24px solid #D1B67F
}


.border-golden {
  border: 1px solid #D1B67F;
}

.MultiCarousel {
  float: left;
  overflow: hidden;
  padding: 15px;
  width: 100%;
  position: relative;
}

.MultiCarousel .MultiCarousel-inner {
  transition: 1s ease all;
  float: left;
}

.MultiCarousel .MultiCarousel-inner .item {
  float: left;
}

.MultiCarousel .MultiCarousel-inner .item>div {
  text-align: center;
  padding: 10px;
  margin: 10px;
  background: #f1f1f1;
  color: #666;
}

.MultiCarousel .leftLst,
.MultiCarousel .rightLst {
  position: absolute;
  border-radius: 50%;
  top: calc(50% - 20px);
}

.MultiCarousel .leftLst {
  left: 0;
}

.MultiCarousel .rightLst {
  right: 0;
}

.MultiCarousel .leftLst.over,
.MultiCarousel .rightLst.over {
  pointer-events: none;
  background: white;
  color: black;
  border: none
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}


/* Overlay */
.cta-background {
  width: 720px;
  margin-top: 10px;
  height: 457px;
  left: calc(50% - 720px/2);
  top: 0px;
  bottom: 0px;

  background: #374957;
  border-radius: 500px 500px 0px 0px;
}

.cta-background-new {
  /* width: 720px; */
  margin-top: 10px;
  height: 457px;
  left: calc(50% - 720px/2);
  top: 0px;
  bottom: 0px;

  background: #374957;
  border-radius: 500px 500px 0px 0px;
}