 /* Font Definitions */
 @font-face {
     font-family: "Cambria Math";
     panose-1: 2 4 5 3 5 4 6 3 2 4;
 }

 @font-face {
     font-family: Aptos;
 }

 @font-face {
     font-family: "Segoe UI";
     panose-1: 2 11 5 2 4 2 4 2 2 3;
 }

 /* Style Definitions */
 p.MsoNormal,
 li.MsoNormal,
 div.MsoNormal {
     margin-top: 0cm;
     margin-right: 0cm;
     margin-bottom: 8.0pt;
     margin-left: 0cm;
     line-height: 115%;
     font-size: 12.0pt;
     font-family: "Aptos", sans-serif;
 }

 p.paragraph,
 li.paragraph,
 div.paragraph {
     mso-style-name: paragraph;
     margin-right: 0cm;
     margin-left: 0cm;
     font-size: 12.0pt;
     font-family: "Times New Roman", serif;
 }

 span.normaltextrun {
     mso-style-name: normaltextrun;
 }

 span.eop {
     mso-style-name: eop;
 }

 .MsoChpDefault {
     font-size: 12.0pt;
     font-family: "Aptos", sans-serif;
 }

 .MsoPapDefault {
     margin-bottom: 8.0pt;
     line-height: 115%;
 }

 @page WordSection1 {
     size: 595.3pt 841.9pt;
     margin: 72.0pt 72.0pt 72.0pt 72.0pt;
 }

 div.WordSection1 {
     page: WordSection1;
 }

 .heading-tankyou {
     font-size: 60px;
 }

 .thanks-header {
     padding: 170px 0 80px 0;
 }

 @media all and (max-width:767px) {
     .heading-tankyou {
         font-size: 35px;
         padding: 0 15px;
     }
 }